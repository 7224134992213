import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import styles from './WaybillSignatureModal.module.scss';

const NameClarificationInput = ({
  inputName,
  selectedSignature,
  onChange,
  extraStyles,
  error,
 }) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (selectedSignature) {
      setInputValue(selectedSignature[inputName]);
      onChange(selectedSignature[inputName]);
    }
  }, [selectedSignature]);

  const handleInputChange = (value) => {
    setInputValue(value);
    onChange(value);
  };

  return (
    <>
      <label htmlFor={inputName} className={styles.nameClarificationLabel}>Nimenselvennys:</label>
      <input name={inputName} id={inputName} type="text"
        value={inputValue}
        onChange={(event) => handleInputChange(event.target.value)}
        style={extraStyles}
      />
      {error && <span style={{ color: '#ff0000', marginLeft: 10 }}>{error}</span>}
    </>
  );
};

NameClarificationInput.propTypes = {
  inputName: PropTypes.string.isRequired,
  selectedStignature: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  extraStyles: PropTypes.object,
  error: PropTypes.string,
};

export default NameClarificationInput;
