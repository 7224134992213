import { get } from "lodash";
import { default as fetchTransactionInputs } from "../../../utils/fetchTransactionInputs";
import { getWaybillDataQuery } from "../../../api/graphql/getWaybillData";
import { getWaybillSignatureQuery } from "../../../api/graphql/getWaybillSignature";

export const isSet = (value) => {
  return value !== null && value !== undefined && value !== "";
};

export const isWaybillSigned = (signatures, type) => {
  const driverSignatureIsSet = isSet(signatures?.driverSignature);
  const senderSignatureIsSet = isSet(signatures?.senderSignature) || (isSet(signatures?.senderSignatureNotAvailable) && signatures.senderSignatureNotAvailable);
  const receiverSignatureIsSet = isSet(signatures?.receiverSignature) || (isSet(signatures?.receiverSignatureNotAvailable) && signatures.receiverSignatureNotAvailable);

  const result = type === 'load'
    ? driverSignatureIsSet && senderSignatureIsSet
    : driverSignatureIsSet && senderSignatureIsSet && receiverSignatureIsSet;

  return result;
}

export const getWaybillTransactionData = (order, isOngoingLoad, isOngoingUnload, isIncompleteUnload) => {
  // Fetch entered transaction  data
  const transactionLoadInputs = fetchTransactionInputs();

  // loading is ongoing. Use the user input values (if available)
  get(order, "rows", []).forEach((row) =>
    row.transactions.forEach((transaction) => {
      // The apporach here is a bit weird and a little redundant with the improved caching
      // in the app. We use local storage to record inputs (waybiull numbers and amounts)
      // for each transaction. This data is then retrieved when the transactionStatus is changed
      // via the Mutation. I think it has originally been used so that users could enter data on
      // various screens and it would be retreived when the Mutation was submitted.

      // At some point this approach may need reworked.

      // First for the transaction in the orderRow, check if there is any waybill/amount data
      // in localstorage for either pickup transaction ID or unload transaction ID.
      const pickupInput = transactionLoadInputs[transaction.pickupTransactionId] || {};
      const unloadInput = transactionLoadInputs[transaction.unloadTransactionId] || {};

      // For pickups users can change BOTH the waybill and amount.
      // For unloading, users can ONLY change the amnount - the waybill is locked to whatever
      // was stored when the goods were loaded into the truck.

      // To set the waybill, first check if the waybill was updated when the goods were loaded (from localstorage).
      // If there is no record in local storage, use the waybill in the data returned from SISU. This value can only
      // be changed by users when goods are loaded. It cannot be changed when goods are unloaded.
      transaction.waybillNum = pickupInput.waybill ? pickupInput.waybill : transaction.waybillNum;

      // For the amount, users can update this when loading & unloading goods. This means a priority order is used
      // to determine which value to use:
      // 1. Use data eneterd for unloading transaction id (only when unloading)
      // 2. Use data eneterd for loading transaction id (both when loading & unloading)
      // 3. Use data from SISU if available.
      if (!isOngoingLoad && !isIncompleteUnload) {
        transaction.actualAmount = transaction.actualAmount || undefined;
      } else if (isOngoingUnload && unloadInput.amountLoaded !== undefined && `${unloadInput.amountLoaded}`.length > 0) {
        transaction.actualAmount = unloadInput.amountLoaded
      } else if (pickupInput.amountLoaded !== undefined && `${pickupInput.amountLoaded}`.length > 0) {
        transaction.actualAmount = pickupInput.amountLoaded
      } else {
        transaction.actualAmount = transaction.actualAmount || undefined;
      }
      // transaction.actualAmount =
      //   isOngoingUnload && unloadInput.amountLoaded !== undefined && `${unloadInput.amountLoaded}`.length > 0
      //     ? unloadInput.amountLoaded
      //     : pickupInput.amountLoaded !== undefined && `${pickupInput.amountLoaded}`.length > 0
      //       ? pickupInput.amountLoaded
      //       : transaction.actualAmount || undefined;

      transaction.amountLoaded = pickupInput.amountLoaded ? pickupInput.amountLoaded : transaction.amountLoaded;
      transaction.amountUnloaded = unloadInput.amountLoaded ? unloadInput.amountLoaded : transaction.amountUnloaded;;

      // WeightNoteNumbers are not loaded from SISU. It is an open non-required field for users to fill in. Load from localstorage if available.
      transaction.weightNoteNumberLoading = pickupInput.weightNoteNumberLoading
        ? pickupInput.weightNoteNumberLoading
        : transaction.weightNoteNumberLoading
          ? transaction.weightNoteNumberLoading
          : "";
      transaction.weightNoteNumberUnloading = unloadInput.weightNoteNumberUnloading
        ? unloadInput.weightNoteNumberUnloading
        : transaction.weightNoteNumberUnloading
          ? transaction.weightNoteNumberUnloading
          : "";

      transaction.container1Load = pickupInput.container1Load
        ? pickupInput.container1Load
        : transaction.container1Load
          ? transaction.container1Load
          : "";
      transaction.container2Load = pickupInput.container2Load
        ? pickupInput.container2Load
        : transaction.container2Load
          ? transaction.container2Load
          : "";
      transaction.container3Load = pickupInput.container3Load
        ? pickupInput.container3Load
        : transaction.container3Load
          ? transaction.container3Load
          : "";
      transaction.container4Load = pickupInput.container4Load
        ? pickupInput.container4Load
        : transaction.container4Load
          ? transaction.container4Load
          : "";
      transaction.container1Unload = unloadInput.container1Unload
        ? unloadInput.container1Unload
        : transaction.container1Unload
          ? transaction.container1Unload
          : "";
      transaction.container2Unload = unloadInput.container2Unload
        ? unloadInput.container2Unload
        : transaction.container2Unload
          ? transaction.container2Unload
          : "";
      transaction.container3Unload = unloadInput.container3Unload
        ? unloadInput.container3Unload
        : transaction.container3Unload
          ? transaction.container3Unload
          : "";
      transaction.container4Unload = unloadInput.container4Unload
        ? unloadInput.container4Unload
        : transaction.container4Unload
          ? transaction.container4Unload
          : "";
    })
  );

  return order;
}

export const generateCompositeKey = (orderId, waybillNumber) => `${orderId}_${waybillNumber}`;

export const getWaybillSignatureWrapperFunction = async (appSyncClient, routeId, orderId, waybillNumber) => {
  if (!routeId || !orderId || !waybillNumber) {
    console.log('need routeId, orderId and waybillNumber!');
    return undefined;
  }

  let result = undefined;
  try {
    const orderInfoComposite = generateCompositeKey(orderId, waybillNumber);
    const response = await appSyncClient.query({
      query: getWaybillDataQuery,
      variables: { routeId, orderInfoComposite },
      fetchPolicy: 'network-only'
    })
    result = response.data.getWaybillData;
  } catch (error) {
    console.log('Query error', JSON.stringify(error, null, 2));
  }

  // if we have loading signatures from the new query, we can return the result
  if (isWaybillSigned(result, 'load')) {
    return result;
  }

  const nonNullSignatureObject = result && Object.entries(result).reduce((acc, [key, value]) => {
    if (value !== null) {
      acc[key] = value;
    }
    return acc;
  }, {});

  // fallback to the old waybill signature query and merge the results
  try {
    const response = await appSyncClient.query({
      query: getWaybillSignatureQuery,
      variables: { routeId, waybillNumber },
      fetchPolicy: 'network-only'
    })
    return { ...response.data.getWaybillSignature, ...nonNullSignatureObject, combinationObject: true }; // prefer the new query result
  } catch (error) {
    console.log('Query error', JSON.stringify(error, null, 2));
  }
};

export const resolveWaybillNumberAndSignatures = async (orderView, waybillData, type, client, routeId) => {
  // we need to fetch the signatures to know whether the waybill is signed or not
  // so that we can enable/disable the waybill/exception buttons
  const transactionLoadInputs = fetchTransactionInputs();
  const transactionId = waybillData.transactions
    .map((transaction) => {
      if (orderView) {
        return type === 'load' ? transaction.pickupTransactionId : transaction.unloadTransactionId;
      }
      return transaction.transactionId;
    })
    .filter(id => id !== undefined);
  
  let signedWithSignatureData = false;
  let waybillNumber = undefined;
  if (waybillData?.transactions) {
    waybillNumber = transactionLoadInputs[transactionId[0]]?.waybill;
    const signatures = await getWaybillSignatureWrapperFunction(client, routeId, waybillData.orderId, waybillNumber);
    signedWithSignatureData = isWaybillSigned(signatures, type);
  }

  if (!signedWithSignatureData) {
    // Transactions may be from different orders but should be of same type, either load/unload.
    // Activate only if all of them are signed even if not displayed (single order view).
    const signedKey = type === 'load' ? 'signedOnLoad' : 'signedOnUnload';
    const isSigned = transactionId.every(t => transactionLoadInputs[t]?.[signedKey] ?? false);
    signedWithSignatureData = isSigned;
  }

  return { waybillNumber, signed: signedWithSignatureData };
};
